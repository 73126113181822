const esTranslations =  {
    'Expired JWT Token': 'La sesión ha caducado',
    'Invalid JWT Token': 'La sesión ha caducado',
    HttpError: 'Error al conectar con el servidor',
    filterProfiles: 'Filtrar perfiles',
    searchProfiles: 'Buscar perfiles',
    noClientsSelected: 'Selecciona un cliente en el selecctor para ver sus cadidaturas',
    selectClient: 'Selecciona un cliente ...',
    summary: 'Información',
    module: 'Módulo',
    processType: 'Tipo de proceso',
    'client.id': 'Cliente',
    'client.name': 'Cliente',
    questions: 'Preguntas',
    "Success Message": 'Operación realizada con éxito',
    filtersUserDefault: 'Filtros por defecto para el usuario',
    filtersUserDefaultHelper: 'Filtros por defecto para el usuario',
    Notification:{
        "Success Message": 'Operación realizada con éxito',
    },
    ra: {
        action: {
            back: 'Volver',
            undo: 'Deshacer',
            add: 'Añadir',
            bulk_actions: '1 elemento seleccionado |||| %{smart_count} elementos seleccionados',
            page: "pagina",
            from: 'Desde',
            cancel: 'Cancelar',
            clone: 'Clonar',
            confirm: 'Confirmar',
            create: 'Crear',
            delete: 'Borrar',
            edit: 'Editar',
            export: 'Exportar',
            list: 'Lista',
            refresh: 'Refrescar',
            save: 'Guardar',
            show: 'Mostrar',
            sort: 'Ordenar',
            search: 'Buscar',
            add_filter: 'Añadir filtro',
            unselect: 'Deseleccionar',
            clear_input_value: 'Limpiar',
            remove_filter: 'Eliminar filtro',
        },
        auth:{
            auth_check_error: 'Por favor, inicia sesión para continuar',
            user_menu: 'Perfil',
            username: 'Usuario',
            password: 'Contraseña',
            sign_in: 'Iniciar sesión',
            sign_in_error: 'Error de autenticación, por favor, inténtalo de nuevo',
            logout: 'Cerrar sesión',
        },
        validation: {
            required: 'Campo requerido',
            number: 'Debe ser un número',
            email: 'Debe ser un email válido',
        },
        input: {
            image: {
                upload_several: 'Arrastra aquí algunos archivos para subirlos, o haz click para seleccionarlos.',
                upload_single: 'Arrastra aquí un archivo para subirlo, o haz click para seleccionarlo.',
            },
            file: {
                upload_several: 'Arrastra aquí algunos archivos para subirlos, o haz click para seleccionarlos.',
                upload_single: 'Arrastra aquí un archivo para subirlo, o haz click para seleccionarlo.',
            }
        },
        navigation: {
            no_results: 'No se han encontrado resultados',
            page_rows_per_page: 'Registros por página:',
            page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
            page_out_of_boundaries: 'El número de página %{page} está fuera de los límites',
        },
        notification: {
            created: 'Elemento creado',
            updated: 'Elemento actualizado',
            http_error: "Error al realizar la operación",
            success_operation: 'Operación realizada con éxito',
            bad_operation: 'Operación fallida',
            deleted: 'Elemento borrado',
            item_doesnt_exist: 'El elemento no existe',
            "Success Message": 'Operación realizada con éxito',
            "Error Message": 'Error al realizar la operación',
            "failed to fetch": 'Error al conectar con el servidor',
            "Network Error": 'Error al conectar con el servidor',
            error: 'Error',
        },
        configurable: {
            customize: 'Personalizar',
        },
        page: {
            error: 'Algo ha ido mal',
            empty: 'No hay elementos',
            "list": "Lista",
            "loading": "Cargando...",
            "create": "Crear %{name}",
            "edit": "%{name} #%{id}",
            "show": "%{name} #%{id}",
        },
        boolean: {
            true: 'Yes',
            false: 'No',
            null: ' ',
        },
        message: {
            message: 'Mensaje',
            error: 'Error',
            "Success Message": 'Operación realizada con éxito',
            "Error Message": 'Error al realizar la operación',
            "loading": 'Cargando...',
        },
        notify: {
            "Success Message": 'Operación realizada con éxito',
        },
        users: {
            max_users_reached: "Número máximo de usuarios alcanzado para el cliente"
        }
    },
    resources: {
        clients: {
            name: 'Clientes',
            fields: {
                name: 'Nombre',
                background_color: 'Color de fondo',
                text_color_principal: 'Color del texto principal',
                text_color_secondary: 'Color del texto secundario',
                candidatures: 'Candidaturas',
                stats_filters_available: 'Filtros habilitados para estadísticas',
                module_talent_acquisition: {
                    enabled: 'Módulo de Adquisición de Talento',
                    skill_weight: 'Peso Skill',
                    profile_weight: 'Peso Profile',
                    weight_skill1: 'Peso Skill 1',
                    weight_skill2: 'Peso Skill 2',
                    weight_skill3: 'Peso Skill 3',
                    inbox_fields: 'Columnas inbox',
                    minimum_percentage_suitable: 'Porcentaje mínimo',
                    token_sendgrid_email_bot_starter_template: 'Sengrid Tpl. Id email para bot-starter',
                    token_sendgrid_email_bot_starter_repush_template: 'Sengrid Tpl. Id email para bot-starter-repush',
                    token_sendgrid_email_bot_starter_recurrent_template: 'Sengrid Tpl. Id email para bot-starter-recurrent',
                    token_sendgrid_email_bot_feedback_template: 'Sengrid Tpl. Id email para bot-feedback',
                    token_sendgrid_chat_template: 'Sendgrid Tpl. Id Postchat',
                    token_sendgrid_citation_template: 'Sengrid Tpl. Id citation',
                    token_sendgrid_email_notice_template: 'Sendgrid Tpl. Notificaciones Genericas',
                    talent_clue_integrated: 'Talent Clue',
                    sms_start_bot_template: 'SMS Template para empezar el bot',
                    sms_repush_bot_template: 'SMS Template para repush bot',
                    sms_feedback_bot_template: 'SMS Template para Feedack bot',
                    sms_start_bot_template_helper: "Reemplazos disponibles {{url_notification}} > URL bot",
                    sms_bot_starter_recurrent_template: 'SMS Template para bot-starter-recurrent',
                    bot_url: "Url bot (Bot)",
                    schedules_available_to_notify_bot_starter: "Horario para poder notificar",
                    schedules_available_to_notify_bot_starter_helper: "https://crontab.guru/#*_10-13,17-20_*_*_1,2,3,4,5",
                    update_candidature_in_days: 'Actualizar candidatos en X días',
                    update_candidature_bot_url: 'Url bot feedback candidatos',
                    whatsapp_sender_id: 'Id del remitente de Whatsapp',
                    waba_key: 'Clave Waba',
                    whatsapp_template: 'Plantilla de whatsapp para bot-starter',
                    whatsapp_test_phone: 'Teléfono para pruebas'
                },
                module_employee_line: {
                    enabled: 'Módulo Linea de Empleado',
                    inbox_fields: 'Columnas inbox',
                    sendgrid_process_new_template: 'Sendgrid Tpl. Id nuevo proceso',
                    sendgrid_process_status_changed_template: 'Sendgrid Tpl. estado cambiado',
                },
                module_on_board: {
                    enabled: 'Módulo Onboarding',
                },
                module_feedback: {
                    enabled: 'Módulo Feedback',
                    sendgrid_feedback_new_template: 'Sendgrid tpl New chatbot'
                },
                module_exit_interview: {
                    enabled: 'Módulo ExitInterview',
                    sendgrid_new_exit_interview_template: 'Sendgrid tpl New chatbot'
                },
                email_from: 'Email de',
                logo: 'Logo',
                currentLogo: 'Logo actual',
                sms_available: 'SMS activado',
                sms_prefix: 'Prefijo SMS',
                max_users: 'Límite de usuarios',
            },
            tabs: {
                colors: 'Colores',
                candidatures: 'Candidaturas',
                modules: 'Módulos',
                inboxes: 'Inboxes',
                default_messages: 'Mensajes por defecto',
                technical_selections: 'Técnicos de selección',
                filters: 'Filtros',
            },
        },
        technical_selections: {
            name: 'Técnicos de selección',
            fields: {
                name: 'Nombre',
                surname: 'Apellido',
                'client.id': 'Cliente',
                phone: 'Teléfono',
                email: 'Email',
                client: 'Cliente',
                password: 'Contraseña',
                avatar: 'Avatar',
                currentAvatar: 'Avatar actual',
            },
        },
        blacklists: {
            name: 'Blacklist',
            fields: {
                'client.id': 'Id',
                'client.name': 'Nombre',
                 clientId: "Cliente",
                "module_type.id": "Módulo",
                files: "Ficheros",
                black_list_import: "Importar",
            },
            modal: {
                document: 'Documento',
                client: 'Cliente',
            },
            notifications: {
                importSuccess: 'Importado con éxito',
            },
            import: 'Importar',
        },
        importButton: {
            import: 'Importar',
            fields: {
                'clientId': 'Id',
                'client.name': 'Nombre',
            }
        },
        onboardingconfigs: {
            fields: {
                name: 'Nombre',
                remote_name: 'Nombre Remoto',
                questions: 'Preguntas',
                cliente: 'Cliente',
                "client.id": 'Cliente',
                "type.name": 'Tipo de nombre de proceso',
                "type.id": 'Tipo de id de proceso',
            },
        },
        exitinterviewconfigs: {
            fields: {
                name: 'Nombre',
                remote_name: 'Nombre Remoto',
                questions: 'Preguntas',
                cliente: 'Cliente',
                "client.id": 'Cliente',
            },
        },
        suggestionfeedbackconfigs: {
            fields: {
                name: 'Nombre',
                remote_name: 'Nombre Remoto',
                questions: 'Preguntas',
                cliente: 'Cliente',
                "client.id": 'Cliente',
                prefix: "Prefijo"
            },
        },
        candidatures: {
            name: 'Candidaturas',
            fields: {
                name: 'Nombre',
                skill_weight: 'Peso Skill',
                profile_weight: 'Peso Profile',
                remote_name: 'Variable',
                weight_skill1: 'Peso Skill 1',
                weight_skill2: 'Peso Skill 2',
                weight_skill3: 'Peso Skill 3',
                questions: 'Preguntas',
                'skill1.id': 'Skill1',
                'skill2.id': 'Skill2',
                'skill3.id': 'Skill3',
                'client.id': 'Cliente',
                client: 'Cliente',
                minimum_percentage_suitable_overwrite: 'Porcentaje mín (Sobrees. cliente)',
                skill_weight_overwrite: 'Peso Skill (Sobreescribir cliente)',
                profile_weight_overwrite: 'Peso Profile (Sobreescribir cliente)',
            },
            tabs: {
                questions: 'Preguntas',
            },
        },
        inboxes: {
            name: 'Columnas inbox',
            fields: {
                name: 'Nombre',
                remote_name: 'Variable',
            },
        },
        modules: {
            name: 'Módulos',
            fields: {
                name: 'Nombre',
            },
        },
        skills: {
            name: 'Skills',
            fields: {
                name: 'Nombre',
            },
        },
        default_messages: {
            name: 'Mensajes por defecto',
            fields: {
                title: 'Título',
                body: 'Cuerpo',
                remote_name: 'Clave',
                'module_type.id': 'Modulo',
                'client.id': 'Cliente',
                interviewer: 'Entrevistador',
                location: 'Ubicación',
            },
        },
        profiles: {
            name: 'Perfiles',
            fields: {
                name: 'Nombre',
            },
        },
        questions: {
            name: 'Preguntas',
            fields: {
                name: 'Nombre',
                profile: 'Perfil',
                variable_field: 'Campo variable',
                is_editable_by_client: 'Editable por cliente',
                is_gpp_showable: 'Debe mostrarse en filtros de GPP',
                remote_name: 'Variables',
                internal_name: 'Nombre interno',
                questions: 'Preguntas',
                answers: 'Respuestas',
                weight: 'Peso',
                'profile.id': 'Perfil',
                'module_type.id': 'Módulo',
            },
            tabs: {
                answers: 'Respuestas',
            },
        },
        answers_skills: {
            name: 'Respuestas Skill',
            fields: {
                name: 'Nombre',
                value: 'Valor respuesta',
                mbtiType_selected: 'Puntúa a:',
                mbti_value: 'Puntúa a:',
                'mbti_value.id': 'Puntúa a:',
                question: 'Preguntas',
                axis: 'Eje',
                'axis.id': 'Eje',
                punctuate: 'Puntúa a skill',
                'skill.id': 'Skill',
                mbtis: 'mbtis',
                axis1: 'axis 1',
                axis2: 'axis 2',
                axis3: 'axis 3',
                axis4: 'axis 4',
            },
            tabs: {
                mbtis: 'Mbti',
            },
        },
        answers_profile: {
            name: 'Respuestas Profile',
            fields: {
                value: 'Valor',
                score: 'Puntuación',
                question: 'Pregunta',
                weight: 'Peso',
                name: 'Nombre',
            },
        },
        axis: {
            name: 'Ejes',
            fields: {
                name: 'Nombre',
                values: 'Valores',
            },
            tabs: {
                values: 'Valores',
            },
        },
        'types/mbtis': {
            name: 'Tipos Mbtis',
            fields: {
                value: 'Valor',
                'axis.id': 'Ejes',
                axis: 'Ejes',
            },
        },
        availabilities: {
            name: 'Disponibilidad',
            fields: {
                label: 'Etiqueta',
                'client.id': 'Cliente',
                position: 'Posición',
                client: 'Cliente',
            },
        },
        reportfields: {
            name: 'Columnas reportes',
            fields: {
                name: 'Nombre',
                remote_name: 'Clave',
                type: 'Tipo',
                'client.id': 'Cliente',
                'module_type.id': 'Módulo',
                order: 'Orden',
            },
        },
        disinteresteds: {
            name: 'No Interesado',
            fields: {
                label: 'Etiqueta',
                'client.id': 'Cliente',
                position: 'Posición',
                client: 'Cliente',
            },
        },
        filters: {
            name: 'Filtros',
            fields: {
                name: 'Nombre',
                remote_name: 'Nombre remoto',
                'moduleType.id': 'Modulo',
                'client.id': 'Cliente',
                'filter_block.id': 'Bloque de filtros',
                'filters_block.id': 'Bloque de filtros',
                'candidature.id': 'Candidatura',
                'type.id': 'Tipo de filtro',
                'type': 'Tipo de filtro',
                'order': 'Orden',
                'module_type.id': 'Módulo',
            },
        },
        filter_block:{
            name: 'Bloques de filtros',
            fields: {
                name: 'Nombre',
                remote_name: 'Nombre remoto',
                'moduleType.id': 'Modulo',
                'client.id': 'Cliente',
            }
        },
        tags: {
            name: 'Etiquetas',
            fields: {
                name: 'Nombre',
                'module_type.id': 'Módulo',
                'client.id': 'Cliente',
                description: 'Descripción',
                order: 'Orden',
            }
        },
        processes: {
            name: 'Proceso',
            fields: {
                remote_name: 'Variable',
                name: 'Nombre',
                "client.id": 'Cliente',
                prefix: 'Prefijo',
                questions: 'Preguntas',
                'type.name': 'Tipo nombre de proceso',
                'type.id': 'Tipo de proceso',
            },
            tabs: {
                questions: 'Preguntas',
            },
        },
        users: {
            name: 'Usuarios cliente',
            fields: {
                technical_selection_user: {
                    enabled: 'Adquisición de talento',
                    supervisor: 'Supervisor',
                },
                rrhh_user: {
                    enabled: 'Linea de empleado',
                    supervisor: 'Supervisor',
                },
                user_authorized_sections: {
                    name: "Secciones autorizadas",
                    section : {
                        inbox: 'Inbox',
                        push_offer: 'Ofertas Push',
                        pull_config: 'Configuración Pull',
                        saved_searches: 'Búsquedas guardadas',
                        planning: 'Planificación',
                        favorites: 'Favoritos',
                        reports: 'Informes',
                        statistics: 'Estadísticas',
                        statistics_dashboard: 'Dashboard estadísticas',
                        evaluative_tests: 'Video entrevistas'
                    }
                },
                'admin_user.enabled': 'Admin',
                'client.id': 'Cliente',
            },
        },
        admin_users: {
            name: 'Usuarios admin',
            fields: {
                'admin_user.role': 'Rol de administrador',
            },
        },
        reminderconfigs: {
            name: 'Recordatorios',
            fields: {
                order: 'Orden',
                days: 'Días',
                sendgrid_template_id: 'Plantilla de Sendgrid',
                'client.id': 'Cliente',
            },
        },
        sms: {
            name: 'Sms',
            fields: {
                client: {
                    id: 'Cliente',
                    name: 'Nombre',
                },
                user: {
                    id: 'Usuario',
                },
                module_type: {
                    id: 'Módulo',
                },
                successfully_at: 'Fecha',
                created_at: 'Creado',
                'created_at.from': 'Desde',
                'created_at.to': 'Hasta',
            },
        },
        sessions: {
            fields: {
                id: "Id",
                created_at: "Fecha de creación",
                finished_at: "Fecha de finalización",
                first_saved_at: "Fecha de primer guardado",
                last_saved_at: "Fecha de último guardado",
                variables: "Variables",
                visited_nodes: "Mensajes enviados",
                from: "Desde",
                to: "Hasta",
                key: "Clave",
                valor: "Valor",
                chatbots: "Chatbots",
                started_at: "Inicio de sesión",
                'variables.email': "Email",
                'variables.nombre': "Nombre",
                'variables.apellidos': "Apellidos",
                'variables.phone': "Teléfono",
                'variables.candidature_id': "Id candidatura",
                'variables.candidature_name': "Nombre candidatura",
                'variables.candidature_remote_name': "Nombre remoto candidatura",
                'variables.candidature_client_id': "Id cliente candidatura",
                'variables.candidature_client_name': "Nombre cliente candidatura",
                'variables.candidature_client_remote_name': "Nombre remoto cliente candidatura",
                'variables.candidature_client_logo': "Logo cliente candidatura",
                'variables.candidature_client_background_color': "Color de fondo cliente candidatura",
                'variables.candidature_client_text_color_principal': "Color de texto principal cliente candidatura",
            }
        },
        rpa_candidates: {
            name: 'RPA Reports',
            fields: {
                session: 'Sesión',
                client_name: 'Cliente',
                goal_value: 'Número de candidatos objetivo',
                current_value: 'Número de candidatos actuales',
                date: 'Fecha',
                client_id: 'Cliente',
            },
        },
    },
    sms_reports: {
        id: "Id",
        client: "Cliente",
        client_id: "Id Cliente",
        candidature_config: "Candidatura",
        unique_candidates: "Candidatos inscritos únicos",
        candidates_applied_email: "Email enviados",
        sent_email: "Email % enviados /inscritos",
        loaded_email: "Email abiertos",
        opened_email: "Email % abiertos /enviados",
        candidates_applied_sms: "SMS enviados",
        sent_sms: "SMS % enviados /inscritos",
        loaded_sms: "SMS abiertos",
        opened_sms: "SMS % abiertos /enviados",
        candidates_applied_whatsapp: "Whatsapp enviados",
        sent_whatsapp: "Whatsapp % enviados /inscritos",
        loaded_whatsapp: "Whatsapp abiertos",
        opened_whatsapp: "Whatsapp % abiertos /enviados",
        loaded: "Abiertos únicos",
        bots_done: "Bots realizados",
        opened_percentage: "Total % realizados /abiertos únicos",
        applied_percentage: "Total % realizados /inscritos",
        rowsPerPageText: 'Registros por pág:',
        rangeSeparatorText: 'de',
        selectAllRowsItemText: 'Todo',
        export: 'Exportar',
        loading: "Cargando...",
        total: "Total",
        No_data_available: "No hay datos disponibles",
        success_operation: "Operación realizada con éxito",
        bad_operation: "Operación fallida",
        title: {
            summary: "Resumen conversión",
            email: "Email enviados (sin repush)",
            sms: "Sms enviados (sin repush)",
            whatsapp: "Whatsapp enviados (sin repush)",
        },
    },
    gero_sessions: {
        title: "Sesiones Gero",
        loading: "Cargando sesiones."
    },
    whatsapp: {
        test: {
            success: "La prueba de envío de WhatsApp ha funcionado",
            error: "La prueba de envío de WhatsApp ha fallado",
        }
    },
    client_statistics: {
        whatsapp: "WhatsApp",
        whatsapp_voucher: "Bono",
        whatsapp_spent: "Consumido",
        whatsapp_details: "Detalles",
        sms: "SMS",
        sms_sent: "Enviados",
        sms_details: "Detalles",
        push_offers: "Ofertas Push",
        push_offer_number: "Número de ofertas",
        push_notified_candidates: "Candidatos notificados",
        pull_offers: "Ofertas Pull",
        pull_notified_candidates: "Candidatos notificados",
        candidates: "Candidatos nuevos",
        enabled_services: "Servicios activos",
        api_request_services: {
            "google:distance_matrix_api": "Distancia - GMaps",
            "here maps:public_transit_api": "Distancia - Here Maps"
        },
    },
    contract: {
        title: "Contrato",
        renovation_success: 'Cliente renovado correctamente',
        renovation_error: 'Error aL renovar el cliente',
        is_velora: "Cliente Velora",
        is_free_trial: "Free trial",
        is_velora_assesment: "Velora Assesment",
        max_jobflows: 'Límite Jobflows',
        max_persons: 'Límite de personas',
        init_trial_at: 'Fecha de inicio de trial',
        expire_at: 'Fecha de finalizacion de trial',
        renew: 'Renovar',
        renew_text: "Vas a renovar este cliente (client_name) por un año más, ¿Estás seguro?",
        accept: 'Aceptar',
        cancel: 'Cancel',
    },
}

export default esTranslations;
