import { fetchUtils, withLifecycleCallbacks, combineDataProviders } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { BASE_URL, API_ENTRYPOINT, GERO_API_BASE_URL, getAuthHeaders } from '../../config/api';
import { handleImageBase64Conversion } from '../../config/Tools';
import {
    retrieveQuestionsDetails,
    formatQuestions,
    formatAnswerSkillData,
    prepareData,
    retrieveAnswersDetails,
    constructVariables,
} from "../../config/Tools/dataHelpers";
import { format } from "date-fns";
import { stringify } from 'query-string';

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({});
    }
    options.headers.set('Accept', 'application/json');
    options.headers.delete('Range');

    options.user = {
        authenticated: true,
        token: getAuthHeaders()['Authorization']
    };
    return fetchUtils.fetchJson(url, options);
};



const customDataProvider = withLifecycleCallbacks(
    simpleRestProvider(`${BASE_URL}${API_ENTRYPOINT}`, fetchJson),
    [
        {
            resource: 'clients',
            afterGetOne: async (params, dataProvider) => {
                const id = params.data.id;
                const response = await dataProvider.getOne('clients', { id });

                const data = prepareData(response.data);

                return { data };
            },
            afterGetList: async (response, params, dataProvider) => {
                const data = response.data.map(json => prepareData(json));
                
                if (document.location.hash === '#/client_statistics') {
                    data.unshift({id: 'all', name: 'Todos'});
                }
                return { data: data, total: response.total };
            },
            beforeCreate: async (params, dataProvider) => handleImageBase64Conversion(params),
            beforeUpdate: async (params, dataProvider) => handleImageBase64Conversion(params),
        },
        {
            resource: 'onboardingconfigs',
            afterGetOne: async (params, dataProvider) => {
                return retrieveQuestionsDetails(params, dataProvider, 'onboardingconfigs');
            },
        },
        {
            resource: 'exitinterviewconfigs',
            afterGetOne: async (params, dataProvider) => {
                return retrieveQuestionsDetails(params, dataProvider, 'exitinterviewconfigs');
            },
        },
        {
            resource: 'suggestionfeedbackconfigs',
            afterGetOne: async (params, dataProvider) => {
                return retrieveQuestionsDetails(params, dataProvider, 'suggestionfeedbackconfigs');
            },
        },
        {
            resource: 'processes',
            afterGetOne: async (params, dataProvider) => {
                return retrieveQuestionsDetails(params, dataProvider, 'processes');
            },
        },
        {
            resource: 'candidatures',
            afterGetOne: async (params, dataProvider) => {
                return retrieveQuestionsDetails(params, dataProvider, 'candidatures');
            },
        },
        {
            resource: 'candidatures',
            beforeCreate: async (params, dataProvider) => {
                const formattedData = formatQuestions(params.data);
                return { ...params, data: formattedData, dataProvider };
            },

            beforeUpdate: async (params, dataProvider) => {
                const formattedData = formatQuestions(params.data);
                return { ...params, data: formattedData, dataProvider };
            },
        },
        {
            resource: 'answers_skills',
            afterGetOne: async (params, dataProvider) => {
                const formattedData = formatAnswerSkillData(params.data);
                return { ...params, data: formattedData, dataProvider }
            },
        },
        {
            resource: 'questions',
            afterGetOne: async (params, dataProvider) => {

                return retrieveAnswersDetails(params, dataProvider, 'questions', 'answers_skills');
            },
            beforeCreate: async (params, dataProvider) => {
                if (params.data.answers) {
                    params.data.answers = params.data.answers.map(answer => ({ id: answer }));
                }else{
                    params.data.answers = [];
                }
                return params;
            },
            beforeUpdate: async (params, dataProvider) => {
                params.data.answers = params.data.answers.map(answer => ({ id: answer }));
                return params;
            },
        },
    ]
);

const veloraProvider = () => {
    const baseUrl = `${BASE_URL}${API_ENTRYPOINT}`;
    const simpleProvider = simpleRestProvider(baseUrl, fetchJson);

    const customProvider = {
        ...simpleProvider,
        getList: async (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const rangeStart = (page - 1) * perPage;
            const rangeEnd = page * perPage - 1;

            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([rangeStart, rangeEnd]),
                filter: JSON.stringify(params.filter),
            };
            const url = `${baseUrl}/${resource}?${stringify(query)}`;

            return fetchJson(url).then(({ headers, json }) => {
                return {
                    data: json.data,
                    total: json.total,
                };
            });

        },
    }

    return withLifecycleCallbacks(
        customProvider,
        [
            {
                resource: 'admin_users',
                beforeCreate: async (params, dataProvider) => {
                    params.data.is_active = true;
                    return params;
                }
            },
            {
                resource: 'users',
                beforeCreate: async (params, dataProvider) => {
                    params.data.is_active = true;
                    return handleImageBase64Conversion(params)
                },
                beforeUpdate: async (params, dataProvider) => handleImageBase64Conversion(params),
            },
        ]
    )
};

const geroDataProvider = {
    getList: async (resource, params) => {
        let url;
        let response;
        let data;

        let variablesObject = {};

        if (params.filter.key && params.filter.valor) {
            variablesObject[params.filter.key] = params.filter.valor;
        }

        switch (resource) {
            case 'sessions':
                params = {
                    ...params,
                    filter: {
                        ...params.filter,
                        date_from: params.filter.from,
                        date_to: params.filter.to,
                        chatbots: params.filter.chatbots || [],
                        variables: variablesObject
                    }
                };

                const currentDate = format(new Date(), 'yyyy-MM-dd');
                if (!params.filter.from || params.filter.from === '') {
                    params.filter.from = currentDate;
                }
                if (!params.filter.to || params.filter.to === '') {
                    params.filter.to = currentDate;
                }

                let desiredFilter = {
                    chatbots: params.filter.chatbots,
                    date_from: params.filter.from,
                    date_to: params.filter.to,
                    variables: constructVariables(params.filter.key, params.filter.valor) || {},
                    page: params.pagination.page,
                    limit: params.pagination.perPage
                };

                url = new URL(`${GERO_API_BASE_URL}/stats/sessions`);
                url.searchParams.append('q', JSON.stringify(desiredFilter));

                response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`API response status: ${response.status}`);
                }

                data = await response.json();
                let contentRange = response.headers.get("Content-Range");
                let totalItems = parseInt(contentRange.split("/")[1]);

                return {
                    data,
                    total: totalItems,
                };

            case 'chatbots':
                url = new URL(`${GERO_API_BASE_URL}/stats/chatbots`);

                response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`API response status: ${response.status}`);
                }

                data = await response.json();
                data.map((e) => {
                    e.name = e.name + " (" + e.id + ")";
                    return e;
                });

                if (params.filter && params.filter.name) {
                    const searchName = params.filter.name.toLowerCase();
                    data = data.filter(chatbot => chatbot.name && chatbot.name.toLowerCase().includes(searchName));
                }

                return {
                    data,
                    total: data.length,
                };

            default:
                throw new Error(`Unsupported resource: ${resource}`);
        }
    },

    getMany: async (resource, params) => {

        let url;
        let response;
        let data;

        switch (resource) {
            case 'sessions':

                break;

            case 'chatbots':
                url = new URL(`${GERO_API_BASE_URL}/stats/chatbots`);

                response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`API response status: ${response.status}`);
                }

                data = await response.json();

                return {
                    data,
                    total: data.length,
                };

            default:
                throw new Error(`Unsupported resource: ${resource}`);
        }
    }
};

const rpaDataProvider = {
    getList: async (resource, params) => {
        let url;
        let data;

        switch (resource) {
            case 'rpa_candidates':

                const currentDate = format(new Date(), 'yyyy-MM-dd');

                const date = params.filter && params.filter.date ? params.filter.date : currentDate;
                const clientIds = params.filter && params.filter.client_id ? params.filter.client_id : [];

                const page = params.pagination.page;
                const perPage = params.pagination.perPage;

                url = new URL(`${BASE_URL}${API_ENTRYPOINT}/babidi/events/rpa_candidates`);
                url.searchParams.append('date', date);
                url.searchParams.append('page', page);
                url.searchParams.append('limit', perPage);

                if (clientIds.length > 0) {
                    clientIds.forEach((clientId) => {
                        url.searchParams.append('clients[]', clientId);
                    });
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: getAuthHeaders(),
                    });

                    if (!response.ok) {
                        throw new Error(`API response status: ${response.status}`);
                    }

                    data = await response.json();
                    let contentRange = response.headers.get("Content-Range");
                    let totalItems = parseInt(contentRange.split("/")[1]);

                    const dataWithIds = data.map((item, index) => ({
                        ...item,
                        id: index.toString(),
                    }));
                    return {
                        data: dataWithIds,
                        total: totalItems,
                    };
                } catch (error) {

                    throw new Error(`Error fetching data: ${error.message}`);
                }
            default:
                throw new Error(`Unsupported resource: ${resource}`);
        }
    },
};

const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'clients':
        case 'filters':
        case 'filter_block':
        case 'candidatures':
        case 'modules':
        case 'skills':
        case 'tags':
        case 'disinteresteds':
        case 'reportfields':
        case 'inboxes':
        case 'default_messages':
        case 'sms_reports/client_list':
        case 'onboardingconfigs':
        case 'questions':
        case 'profiles':
        case 'answers':
        case 'answers_profile':
        case 'answers_skills':
        case 'axis':
        case 'types/mbtis':
        case 'exitinterviewconfigs':
        case 'suggestionfeedbackconfigs':
        case 'availabilities':
        case 'processes':
        case 'processtypes':
        case 'blacklists':
        case 'sms_reports':
            // case 'gero_status':
            return customDataProvider;
        case 'chatbots':
        case 'sessions':
            return geroDataProvider;
        case 'rpa_candidates':
            return rpaDataProvider;
        case 'users':
            return veloraProvider();
        case 'admin_users':
            return veloraProvider();
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

export default dataProvider;
