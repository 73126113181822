import * as React from 'react';
import i18n from "../../../assets/i18n";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import * as XLSX from 'xlsx';
import { Description } from '@mui/icons-material';
import { Button } from 'react-admin';

export default function LdeHistoryOfClient({ data: statistics }) {
  const from = statistics.from
  const to = statistics.to
  const clientStatisticRows = statistics?.rows ? Object.values(statistics?.rows) : []

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([]);

    const header = [
      'Client', 'Session ID', 'Created At', 'Prefix', 'Type', 'Question', 'Answer'
    ];

    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

    clientStatisticRows.forEach(clientStats => {
      const row = [
        clientStats?.c_name ? `${clientStats.c_name} (${clientStats.c_id})` : '-',
        clientStats?.botId ?? '-',
        clientStats?.createdAt?.date ?? '-',
        clientStats?.p_prefix ?? '-',
        clientStats?.p_name ?? '-',
        clientStats?.question ?? '-',
        clientStats?.answer ?? '-'
      ];
      XLSX.utils.sheet_add_aoa(worksheet, [row], { origin: -1 });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Historial de LdE');
    XLSX.writeFile(workbook, `Historial_LdE_${from}_a_${to}.xlsx`);
  };

  return (
    <>
      <Button variant="contained" onClick={exportToExcel} sx={{ marginBottom: 2 }}>
        <Description /> Exportar a Excel
      </Button>
      <TableContainer sx={{overflowY: 'auto', maxWidth: '100%'}} component={Paper}>
        <Table size="small" aria-label="a dense table" sx={{minWidth: 'max-content'}}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7}><strong>Historial de preguntas/respuestas LdE ({from} al {to})</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Session ID</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Prefix</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientStatisticRows.map(function (clientStats) {
              return (
                <TableRow>
                  <TableCell>{clientStats?.c_name + ' (' + clientStats?.c_id + ')'}</TableCell>
                  <TableCell>{clientStats?.botId}</TableCell>
                  <TableCell>{clientStats?.createdAt?.date}</TableCell>
                  <TableCell>{clientStats?.p_prefix}</TableCell>
                  <TableCell>{clientStats?.p_name}</TableCell>
                  <TableCell>{clientStats?.question}</TableCell>
                  <TableCell>{clientStats?.answer}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}